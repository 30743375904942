@import '../../variables.scss';

.breadcrumb {
  text-align: center;
  justify-content: center;
  font-size: 1rem;
  padding: 4em 0.5em 0.5em 0.5em;

  a {
    color: $link-color;
  }

  a:hover {
    color: $primary-color;
  }

  &__crumb {
    display: inline;

    &__name {
      margin-right: 0.5em;
    }
  }
}
