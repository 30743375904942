@import '../../variables.scss';

.store {
  text-align: center;
}

.store__products {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20em, 1fr));
  grid-gap: 0.625em;
  margin-top: 2em;
}

.store__product {
  background: $page-color;
  margin: 2em;
  padding-top: 1em;
  padding-bottom: 1em;
}

.store__product:hover {
  background-color: $page-color;
  cursor: pointer;
  text-decoration: none;
  transform: scale(1.05);
  box-shadow: 0 10px 20px $primary-color, 0 4px 8px rgba(0, 0, 0, 0.06);
  border: solid;
  border-width: 0.1em;
  border-color: $primary-color;
}

.store__product__image {
  img {
    width: 15.125em;
    height: auto;
  }
}

.store__product__title {
  font-size: x-large;
  margin: 1em;
}

.store__product__description,
.store__product__posting {
  margin: 1em;
}

.store__product__price {
  font-size: x-large;
  margin: 1em;
  color: $primary-color;
}
