@import '../../variables.scss';

* {
  box-sizing: border-box;
}

.skills__section {
  width: 100%;
  text-align: left;
  padding-left: 0;
  padding-right: 0;
  margin-left: 0;
  margin-right: 0;
}

.skills__title {
  margin: 1em 0em;
  font-weight: bold;
  font-size: 1.2em;
  padding: 0.5em;
  color: $primary-color;
}

.skills__title__icon {
  color: $link-color;
}

.skills__download {
  margin-bottom: 1em;
  color: $primary-color;
}

.skills {
  text-align: center;
  align-items: center;

  &__download:hover {
    color: $primary-color;
  }

  &__education {
    background-color: $link-color;
    color: $page-color;
    border-radius: 3.125em;
    margin: 1em;
    padding: 2em;
  }

  ul {
    list-style-type: none;
    li {
      list-style-type: none;
      margin: 1em;
    }
  }
}

.skills__overviewSection {
  padding-left: 0;
  padding-right: 0;
  margin-left: 5em;
  margin-right: 5em;
}

@media screen and (max-width: 600px) {
  ::-webkit-scrollbar {
    display: none;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }

  .skills__overviewSection {
    width: 100%;
    text-align: left;
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0;
  }

  .skills__section {
    width: 100%;
    text-align: left;
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0;
  }
}
