@import '../../variables.scss';
.recipe {
  word-break: break-word;

  &__action {
    padding-bottom: 1em;
  }

  &__instructions {
    white-space: pre-line;
  }

  &__name {
    text-align: left;
    font-size: 2em;
  }
}

.recipe:last-child {
  border-bottom: none;
}

.recipe__row {
  margin: 1em;
}

.recipe__label {
  font-weight: bold;
}

.recipe__value--indented {
  margin-top: 1em;
  margin-left: 1em;
}

@media screen and (max-width: 600px) {
}
