@import '../../../variables.scss';

input {
  border: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: $link-color;
  padding: 0.75em;
  border-radius: 0.1875em;
  font-size: 0.875em;
  transition: 0.3s all;
  margin: 1em;
  border-bottom: 2px solid $gray-background;
  font-family: $font-family;
}

.input--large {
  width: 65%;
}

.input--medium {
  width: 25%;
}

input:focus {
  outline: none;
  background: $link-color;
}
