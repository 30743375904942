@import '../../variables.scss';

.skills__tech {
  background: $link-color;
  border-radius: 3.125em;
  width: 90%;
}
.details__tech {
  background: $link-color;
  border-radius: 3.125em;
  width: 100%;
}

.skills__tech__icons,
.details__tech__icons {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20em, 1fr));
  grid-gap: 0.625em;
  margin-top: 2em;
}

.skills__tech__icons__item,
.details__tech__icons__item {
  img {
    width: 3.125em;
    height: auto;
  }

  img:hover {
    transform: scale(1.15);
  }
}

.skills__tech__icons__item__name,
.details__tech__icons__item__name {
  color: $page-color;
  font-size: small;
  margin: 0.5em;
}

@media only screen and (max-width: 768px) {
  .skills__tech__icons,
  .details__tech__icons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    margin-top: 2em;
  }
}
