@import '../../variables.scss';

.media__row {
  display: flex;
  flex-wrap: wrap;
  padding: 0 1em;
  margin-top: 1em;
}

.media__title {
  text-align: center;
  font-size: large;
  padding-top: 2em;
  padding-bottom: 2em;
  background-color: $primary-color;
}

.media__title a {
  color: $link-color;
}

.media__column {
  flex: 25%;
  max-width: 25%;
  padding: 0 0.3em;
}

.media__column img {
  vertical-align: middle;
  width: 100%;
  transform: scale(0.75);
}

.media__column img:hover {
  transform: scale(1);
}

.media__social {
  display: flex;
  justify-content: center;
  font-size: $typing-font;
  margin: 1em 1em 0em 1em;
}

@media screen and (max-width: 800px) {
  .media__column {
    flex: 50%;
    max-width: 50%;
  }
}

@media screen and (max-width: 600px) {
  .media__column {
    flex: 100%;
    max-width: 100%;
  }
}
