@import '../../variables.scss';

.recipe-list {
  text-align: left;
  width: 50%;
}

.recipe-list__add-recipe-btn-conatiner {
  text-align: center;
  padding: 0em 0 0.5em 1em;
}

.recipe-list__count {
  text-align: center;
  font-size: large;
}

@media screen and (max-width: 600px) {
  .recipe-list {
    width: auto;
  }
}
