@import '../../../variables.scss';

.googleLogin {
  margin: 2em;
}

.googleLogin__body a {
  background-color: $link-color;
  padding: 12px;
  margin-top: 1em;
}
.googleLogin__body a:hover {
  cursor: pointer;
}
