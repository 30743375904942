@import '../../variables.scss';

.home__video {
  video {
    height: 100%;
    width: 100%;
    margin-bottom: 1em;
    pointer-events: none;
  }

  video::-webkit-media-controls-start-playback-button {
    display: none !important;
    -webkit-appearance: none;
  }

  video::-webkit-media-controls {
    display: none !important;
  }
}

.home__section {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  color: $link-color;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: $link-color;
}

.home__section__hobbies {
  text-align: left;
  max-width: 100%;
  padding-right: 5em;
  padding-left: 5em;
  padding-top: 1em;
  padding-bottom: 2.5em;
}

.home__section__introduction {
  text-align: left;
  max-width: 100%;
  padding-right: 5em;
  padding-left: 5em;
  padding-top: 1em;
  padding-bottom: 2.5em;
}

.home__section__summary {
  text-align: left;
  justify-content: center;
  max-width: 100%;
  padding-right: 5em;
  padding-left: 5em;
  padding-top: 1em;
  padding-bottom: 1em;
}

.home__section__name {
  margin-top: 1em;
  font-size: xx-large;
  color: $page-color;
}

.home__section__description {
  color: $page-color;
  font-style: italic;
  margin-bottom: 2em;
}

@media screen and (max-width: 860px) {
  .home__section__login {
    font-size: medium;
    width: auto;
  }

  .home__section__introduction {
    width: 100%;
    text-align: left;
    padding-left: 0;
    padding-right: 1em;
    margin-left: 0;
    margin-right: 0;
  }

  .home__section__summary {
    width: 100%;
    text-align: left;
    padding-left: 0;
    padding-right: 1em;
    margin-left: 0;
    margin-right: 0;
  }
}
