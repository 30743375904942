@import '../../../variables.scss';

.login {
  align-items: center;
  text-align: center;

  &__form {
    align-items: center;
    text-align: center;
  }
}

.login__logoContainer {
  display: flex;
  justify-content: center;
}

.login__logo {
  content: url('../../../img/logo.png');
  width: auto;
  height: 10em;
  margin: 1em 0 0 0;
}

.login__forgotPassword,
.login__signup {
  margin: 1em 1em 0 1em;
}

.login__question {
  margin: 2em 0 0 0;
}

.login__signup {
  margin: 1em 1em 1em 1em;
}

@media screen and (max-width: 850px) {
  .card {
    width: 80%;
  }
}
