$primary-color: hsl(184, 100%, 30%);
$secondary-color: #ce93d8;
$tertiary-color: lightblue;
$link-color: #e7e6dd;
$page-color: #212121;
$gray-background: #d3d3d3;
$gray-background-gradient: #7f8c8d;
$font-family: 'NunitoSans';
$content-flex: 9;
$fontsize-p: 1.2rem;
$fontsize-nav: 1em;
$typing-font: 3rem;
$information: #90caf9;
