@import '../../../variables.scss';

.details {
  text-align: center;
  font-size: medium;
}

.details hr {
  border: 1px solid $gray-background;
  margin: 0;
  margin-left: 0.3em;
  width: 50%;
  opacity: 0.5;
  margin: auto;
}

.details__heading {
  a {
    justify-content: center;
    text-align: center;
  }
}

.details__logo {
  list-style-type: none;
  margin: 1em;
  white-space: nowrap;

  img {
    display: block;
    margin: auto;
    object-fit: cover;
    width: auto;
    height: 6em;
  }
  img:hover {
    transform: scale(1.15);
  }
}

.details__item {
  line-height: 1.5625em;
  margin-left: 20vw;
  margin-right: 20vw;
  margin-bottom: 1em;
  margin-top: 1em;
  color: $page-color;
}

.details__techUsed {
  display: flex;
  flex-wrap: wrap;
  line-height: 1.5625em;
  margin: 1em 1em 2em 1em;
  padding: 1em 1em 2em 1em;
  font-style: italic;
  font-size: small;
  color: $page-color;
  justify-content: center;
}

.details__techUsed__tech {
  margin: 0.5em;
  padding: 0.5em;
  border: 1px solid $gray-background;
  background-color: $gray-background;
  color: $page-color;
  font-size: small;
}

@media screen and (max-width: 600px) {
  ::-webkit-scrollbar {
    display: none;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }

  .details__item {
    margin-left: 10vw;
    margin-right: 10vw;
  }
}
