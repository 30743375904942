@import '../../variables.scss';

.post {
  color: $link-color;
  margin: 0em;
  padding: 0em;
  margin-bottom: 1em;
}

.post__caption {
  padding: 1em 1em;
  margin: 0em;
  background-color: $link-color;
  color: $page-color;
  text-align: center;
}

.post__image-bg {
  background-color: $link-color;
}

.post__image img {
  width: 100%;
}
