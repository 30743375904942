@import '../../variables.scss';

.poi {
  display: flex;
  flex-flow: row;
  color: $link-color;
}

.poi__item {
  display: inline-block;
  margin: 1em;
  line-height: 1.5625em;
  font-weight: 400;
  flex-direction: column;
  max-width: calc(100% / 3);
}

.poi__item__title {
  text-align: left;
  margin-bottom: 1.25em;
  font-weight: 300;
  font-size: 1.25em;
  padding: 1em 1em 1em 0.2em;
  white-space: nowrap;
  color: $tertiary-color;
}

.poi__item__title__icon,
.poi__item__link__icon {
  color: $link-color;
}

.poi__item__content {
  text-align: left;
  line-height: 1.5625em;
  white-space: pre-line;
}

.poi__item__link {
  a {
    color: $primary-color;
  }
  margin-top: 1em;
}

@media screen and (max-width: 600px) {
  .poi {
    flex-flow: column;
  }

  .poi__item {
    max-width: calc(100% / 1);
  }

  .poi__item__title,
  .poi__item__content,
  .poi__item__link {
    text-align: left;
  }
}
