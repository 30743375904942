@import '../../variables.scss';

.menuItem {
  margin-bottom: 0.5em;
  color: $page-color;
}

.menuItem > li > a:hover {
  color: $primary-color;
}

.menuItem--active {
  border-bottom: 1px solid $primary-color;
  transition: border-bottom 0.5s ease-in-out;
  font-weight: bold;
}
