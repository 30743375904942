@import '../../variables.scss';
.dashboard {
  align-items: center;
  text-align: center;
  background-color: $link-color;

  &__greeting {
    margin: 1em;
    color: $page-color;
  }

  &__section {
    color: $page-color;
    border-radius: 4px;
    margin: 0.5em;
  }
}

.dashboard__information {
  background-color: $information;
  margin: 1em;
  padding: 1em;
  border-radius: 1em;
}

.dashboard__information__item {
  color: $page-color;
}

.dashboard-header {
  text-align: center;
  display: inline;
  margin: 1em;
}

@media screen and (max-width: 600px) {
  .dashboard-header {
    text-align: center;
    display: inline;
    margin: 1em;
  }
  .dashboard {
    align-items: center;
    text-align: center;

    &__greeting {
      font-size: large;
      margin: 1em;
    }
  }
}
