@import '../../variables.scss';

.footer {
  padding: 1em 0em 1em 0em;
  bottom: 0;
  width: 100%;
  justify-content: center;
  text-align: center;
  font-size: small;
  color: $page-color;
  background-color: $link-color;
}

.footer__logo {
  margin: 0em 3em 1em 3em;
}

.footer__logo img {
  width: auto;
  height: 8em;
}

.footer__details {
  hr {
    width: 70%;
    margin-right: auto;
    margin-left: auto;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    border: 1px solid rgb(92, 90, 90);
  }
}

ul {
  margin: 0em;
  padding: 0em;
}
ul li {
  list-style-type: none;
  margin: 1em;
}

.footer__details__item {
  font-size: medium;
}
