.ingredient-grid {
  display: grid;
  grid-template-columns: repeat(2, auto);
  row-gap: 1em;
  column-gap: 2em;
  justify-content: flex-start;
}

@media (max-width: 1000px) {
  .ingredient-grid {
    grid-template-columns: 1fr;
  }
}
