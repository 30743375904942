@import '../../variables.scss';

.navbar {
  text-align: center;
  padding: 0.5rem;
  height: 4rem;
  min-width: 100%;
  display: flex;

  ul {
    display: flex;
    list-style: none;
    font-size: $fontsize-nav;
    list-style-type: none;
    text-decoration: none;
    align-items: center;
  }

  li {
    display: inline;
  }

  a {
    text-decoration: none;
    color: $link-color;
  }
}

.navbar {
  display: none;
}

.navbar.active {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  color: $link-color;
  justify-content: center;
  background: transparent;

  &__profile,
  &__dashboard,
  &__logout,
  &__home {
    color: $primary-color;
  }
}

.authentication {
  text-align: center;
  padding: 0.5em;
  a {
    color: $link-color;
    list-style-type: none;
    text-decoration: none;
  }
  a:hover {
    color: $gray-background;
  }

  &__dashboard {
    a {
      color: $link-color;
      list-style-type: none;
      text-decoration: none;
    }
    a:hover {
      color: $gray-background;
    }
  }
}

@media screen and (max-width: 600px) {
  .navbar.active {
    display: flex;
    text-align: center;

    ul {
      -webkit-flex-wrap: wrap;
      flex-wrap: wrap;
      font-size: 0.9em;
    }

    li {
      margin: 0px 0.625em 0.3125em;
    }
  }
}
