@import '../../../variables.scss';

.forgot {
  align-items: center;
  text-align: center;
}

.forgot__logoContainer {
  display: flex;
  justify-content: center;
}

.forgot__logo {
  content: url('../../../img/logo.png');
  margin: 1em 0 0 0;
  width: auto;
  height: 10em;
}

.card {
  background-color: #f4f5f9;
  border: none;
}

.forgot__question {
  margin: 1em;
}
.forgot__signup {
  margin: 1em;
}

.forgot__login {
  color: $primary-color;
  margin: 1em;
}

.forgot__login a,
.forgot__signup a {
  margin: 1em 1em 0 1em;
  text-decoration: none;
}

.forgot__login a:hover,
.forgot__signup a:hover {
  text-decoration: none;
}

@media screen and (max-width: 600px) {
  .card {
    width: 80%;
  }
}
