@import '../client/variables.scss';

@font-face {
  font-family: 'NunitoSans';
  src: local('NunitoSans'),
    url(../fonts/NunitoSans-Light.woff2) format('woff');
}

@font-face {
  font-family: 'Raleway';
  src: local('Raleway'),
    url(../fonts/raleway-v19-latin-regular.woff) format('woff');
}

@font-face {
  font-family: 'Consolas';
  src: local('Consolas'), url(../fonts/Consolas.woff) format('woff');
}

@font-face {
  font-family: 'FontAwesome';
  font-weight: normal;
  font-style: normal;
  src: local('FontAwesome'),
    url(../fonts/fontawesome-webfont.woff) format('woff');
}

:root {
  --content-flex: $content-flex;
  --fontsize-p: $fontsize-p;
  --fontsize-nav: $fontsize-nav;
  --typing-font: $typing-font;
}

* {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: $primary-color;
  cursor: pointer;
  font-weight: bolder;

  &:hover {
    font: bold;
    color: $secondary-color;
    text-decoration: none;
  }
}

p {
  font-size: $fontsize-p;
}

h1,
h2,
h3,
h4,
p {
  margin: 1em;
}

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: center;
}

.spacer {
  margin: 1em;
}

.page {
  flex: 1;
  background-color: $page-color;
  color: $link-color;
}

body {
  font-family: $font-family;
}

.content {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 100vh;
  color: $link-color;
}

.contentLight {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: $page-color;
  min-height: 100vh;
  color: $link-color;
  background: $link-color;
}

.content-row {
  display: flex;
  flex-direction: row;
  flex: 1;
  padding: 1em 1em 0 1em;
  background-color: $page-color;
  min-height: 100vh;
}

.center {
  align-items: center;
  justify-content: center;
  text-align: center;
}

card {
  height: auto;
  width: 400px;
  display: block;
  position: relative;
  margin: 50px auto;
  border-radius: 5px;
  box-shadow: 0 1px 3px $page-color, 0 1px 2px $page-color;
  opacity: 0.9;
  transition: all 0.2s ease-in-out;
  background-color: $link-color;
  color: $page-color;
}

label {
  margin: 1em;
}

@media screen and (max-width: 980px) {
  :root {
    --fontsize-nav: 1rem;
    --typing-font: 2rem;
  }
  .content-row {
    flex-direction: column;
  }
  .content-row--reverse {
    flex-direction: column-reverse;
  }

  card {
    width: 95%;
  }
}
