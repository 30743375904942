@import '../../variables.scss';

.todo {
  align-items: center;
  text-align: center;
  border-radius: 6px;
  font-size: 22px;
  font-family: inherit;
  background: $page-color;

  &__header {
    text-align: center;
    margin: 1em;
    padding: 1em;
  }
  &__title,
  &__userName {
    color: $primary-color;
    font-size: x-large;
  }
}

.todo__info {
  background-color: $information;
  margin: 0.5em;
  padding: 0.5em;
  border-radius: 1em;
  color: $page-color;
  font-size: medium;
}

.todo__resetAll {
  color: $primary-color;
  font-size: medium;
  cursor: pointer;
  margin-top: 1em;
  margin-bottom: 1em;
}

.todo__resetAllInfo {
  color: $primary-color;
  font-size: medium;
}

.todo__form {
  align-items: center;
}

.todo__input {
  background-color: transparent;
  border: 0.1em solid $primary-color;
  color: $link-color;
  margin: 0.5em 0.5em 0.5em 0.5em;
  padding: 0.5em 0.5em 0.5em 0.5em;
  border-radius: 0.2em;
  font-family: inherit;
  outline: none;
}

.todo__list {
  width: 17em;
}

.todo__item {
  list-style-type: none;
  cursor: pointer;
  margin: 0.5em 0 1em 0;
  padding: 0.5em;
  text-align: left;
}

.todo__status {
  text-align: center;
}

.todo--delete {
  float: right;
  padding: 0px 4px;
  cursor: pointer;
  color: $primary-color;
}

.todo--delete:hover {
  border-radius: 6px;
  color: $link-color;
}

.todo__item--complete {
  word-wrap: break-word;
  font-size: 1em;
}

.todo__item--todo {
  font-size: 1em;
  word-wrap: break-word;
}

.col {
  width: 15em;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

@media screen and (max-width: 600px) {
  .todo__title {
    font-size: x-large;
  }
  .todo__list {
    font-size: large;
    height: 24em;
    overflow: scroll;
  }

  .todo__todo {
    word-wrap: break-word;
  }

  .todo__status {
    text-align: center;
  }
}
