@import '../../variables.scss';

.contactMe {
  align-items: center;
  text-align: center;
  font-family: $font-family;
}

.contactMe__avatar {
  content: url('../../img/avatar.png');
  margin: 1em 0 0 0;
  width: 8.125em;
  height: 8.125em;
}

.contactMe__avatarContainer {
  display: flex;
  justify-content: center;
  margin: 1em;
}

.contactMe__form {
  display: flex;
  flex-direction: column;
}

.contactMe__message {
  font-size: medium;
}
