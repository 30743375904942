.displaycard {
  border: 0px solid #9a9b9c;
}
.displaycard__picture {
  object-fit: scale-down;
  width: 8em;
  height: 8em;
  border-radius: 50%;
}

.displaycard__picture:hover {
  transform: scale(1.15);
}

.displaycard__details {
  list-style-type: none;
  display: inline-block;
  margin: 2em;
}

.displaycard__item {
  list-style-type: none;
  margin: 1em;
}
