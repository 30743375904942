@import '../../variables.scss';

.social {
  display: flex;
  justify-content: center;
  background-color: transparent;
}

.social__item {
  margin: 0.5em;
  font-size: x-large;
  a {
    color: $primary-color;
  }

  svg:hover {
    transform: scale(1.15);
  }
}
