@import '../../../variables.scss';

.email-verification {
  text-align: center;
  align-items: center;
  &__logo {
    content: url('../../../img/logo.png');
    margin: 1em 0 0 0;
  }

  &__title {
    font-size: x-large;
    margin: 0.5em;
  }

  &__send-verification-email {
    margin: 0.5em;
  }

  &__dashboard {
    color: $primary-color;
  }
}
