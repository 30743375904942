@import '../../variables.scss';

.detailscards {
  display: flex;
  flex-flow: row;
  overflow-x: scroll;
  padding: 2em;
}

.detailscard {
  background-color: $primary-color;
  display: inline-block;
  line-height: 1.5625em;
  font-weight: 400;
  flex-direction: column;
  flex-grow: 1;
  text-align: center;
  margin: 1em 1em 1em 1em;
  padding-top: 1em;
  transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12),
    0.3s box-shadow,
    0.3s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);
  cursor: pointer;
  color: $link-color;
  border: solid;
  border-radius: 10px;
  border-width: 0.1em;
  color: $link-color;
  &:hover {
    cursor: pointer;
    text-decoration: none;
    transform: scale(1.05);
    box-shadow: 0 5px 10px $primary-color, 0 4px 8px rgba(0, 0, 0, 0.06);
    border: solid;
    border-radius: 10px;
    border-width: 0.1em;
    border-color: $primary-color;
    color: $link-color;
  }

  a {
    color: $link-color;
    margin-left: auto;
    margin-right: auto;
  }
}

.detailscard__picture {
  display: block;
  margin: auto;
  object-fit: contain;
  width: 6em;
  height: 6em;
}

.detailscard__details {
  text-align: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  list-style-type: none;
  padding-right: 1em;
}

.detailscard__item,
.detailscard__item__container {
  list-style-type: none;
  margin: 1em;
  white-space: nowrap;
  border-radius: 10px;
}

.detailscard__item__title {
  color: $link-color;
}

.detailscard__item__container {
  background-color: $gray-background;
  background-image: linear-gradient(
    315deg,
    $gray-background 0%,
    $gray-background-gradient 74%
  );
  padding: 1em;
}

@media screen and (max-width: 779px) {
  .detailscards {
    overflow-x: scroll;
    mask-image: linear-gradient(
      to right,
      transparent,
      $link-color 10px,
      $link-color 90%,
      transparent
    );
  }

  .detailscard {
    display: inline-block;
    width: 100%;
    margin-right: 20px;
    line-height: 1.5625em;
    font-weight: 400;
  }
}
