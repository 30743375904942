@import '../../../variables.scss';

.updateProfile {
  align-items: center;
  text-align: center;
}

.updateProfile__question {
  margin: 2em 0 0 0;
}

.updateProfile__login {
  margin: 1em;
}

.updateProfile__login a:hover {
  color: $primary-color;
}

.updateProfile__form {
  color: $primary-color;
}

label {
  color: $page-color;
  justify-content: center;
}
