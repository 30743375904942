@import '../../../variables.scss';

.header {
  display: flex;
  box-sizing: border-box;
  width: 100%;
  background: transparent;
  position: fixed;
  top: 0;
  overflow: hidden;
  z-index: 1;
}

.header.active {
  background: $page-color;
  opacity: 0.8;
}
