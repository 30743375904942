@import '../../../variables.scss';

.signup {
  align-items: center;
  text-align: center;
}

.signup__logoContainer {
  display: flex;
  justify-content: center;
}

.signup__logo {
  content: url('../../../img/logo.png');
  margin: 1em 0 0 0;
  width: auto;
  height: 10em;
}

.card {
  background-color: $page-color;
  color: $link-color;
  border: none;
}

.signup__question {
  margin: 2em 0 0 0;
}

.signup__login {
  margin: 1em 1em 1em 1em;
}

.signup__login a {
  margin: 1em 1em 1em 1em;
}

.signup__login a:hover {
  text-decoration: none;
}
@media screen and (max-width: 600px) {
  .card {
    width: 80%;
  }
}
