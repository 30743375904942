@import '../../variables.scss';

.recipe-edit {
  width: 50%;
}

.recipe-edit__remove-button-container {
  text-align: end;
}

.recipe-edit__details-grid {
  display: grid;
  grid-template-columns: auto 1fr;
  row-gap: 1em;
  column-gap: 2em;
}

.recipe-edit__label {
  font-weight: bold;
  margin-left: 0.5em;
  color: $link-color;
}

.recipe-edit__input {
  font-size: inherit;
  padding: 5px 10;
  outline: none;
  margin-left: 0.5em;
  padding-left: 0.8em;
}

textarea.recipe-edit__input {
  padding-top: 0.5em;
  resize: none;
  height: 18em;
}

.recipe-edit__ingredient-grid {
  display: grid;
  gap: 1em;
  grid-template-columns: repeat(3, auto);
  margin-bottom: 1em;
}

.recipe-edit__add-ingredient-btn-container {
  text-align: center;
  margin-bottom: 1em;
}

.recipe-edit__update-btn-container {
  text-align: center;
  margin: 1em;
}

.recipe-edit__add-ingredient-btn {
  color: $page-color !important;
  padding: 0 4em;
  height: 48px;
  line-height: 48px;
  display: inline-block;
  cursor: pointer;
  border-radius: 3px;
  background: $primary-color !important;
  border-color: $primary-color !important;
  border: 0;
}

@media (max-width: 1000px) {
  .recipe-edit__details-grid,
  .recipe-edit__ingredient-grid {
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 600px) {
  .recipe-edit__details-grid,
  .recipe-edit__ingredient-grid {
    grid-template-columns: 1fr;
  }

  .recipe-edit {
    width: auto;
  }
}
